<script>
export default {
  name: "TopBarComponent"
}
</script>

<template>

  <div class="top-bar py-1">
    <div class="container d-flex justify-content-center">
      <div class="row w-100">
        <div class="col d-flex align-items-center justify-content-center">
          <i class="fas fa-check me-2"></i><span>Veilig</span>
        </div>
        <div class="col d-flex align-items-center justify-content-center">
          <i class="fas fa-check me-2"></i><span>Betrouwbaar</span>
        </div>
        <div class="col d-flex align-items-center justify-content-center">
          <i class="fa-solid fa-phone me-2"></i><span>+31 6 16439395</span>
        </div>
      </div>
    </div>
  </div>

</template>

<style scoped lang="scss">
@import "src/assets/css/custom.scss";
.top-bar {
  font-size: 0.9rem;
  background: $accent;
  color: $secondary;
  border-top: 2px solid $primary;
}

.top-bar .container {
  max-width: 1140px; /* Adjust this if needed */
}

.top-bar .row {
  display: flex;
  justify-content: center; /* Center the columns horizontally */
}

.top-bar .col {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the content in each column */
  font-size: 0.9rem;
}

.top-bar .fas, .top-bar .fa-solid {
  font-size: 0.9rem;
}

</style>