<script>
import AccordionComponent from "@/components/Pages/Over/AccordionComponent.vue";
export default {
  name: "FAQComponent",
  components: {
    AccordionComponent
  }
}
</script>

<template>
  <div class="custom-container mt-5 mb-5">
    <div class="row">
      <div class="col-md-6">
        <h2 v-motion-slide-left class="mt-5">De meest gestelde vragen bij<br> <span
            class="brand-name">Taxi4Go</span>.</h2>
        <p class="mt-3">Voor overige vragen kunt u altijd bellen naar:
          <span class="side-contact">+31 6 16439395</span> <br>
          of mailen naar <span class="side-contact">info@taxi4go.nl</span></p>
      </div>
      <div class="col-md-6">
        <AccordionComponent
            answer="U kunt een taxi reserveren via onze website of door ons te bellen op +31 6 16439395"
            question="Hoe kan ik een taxi reserveren?">
        </AccordionComponent>

        <AccordionComponent
            answer=" U kunt betalen met contant geld, creditcard, pinpas, en via mobiele betalingen zoals Apple Pay en Google Pay."
            question="Wat zijn de betaalmethoden?">
        </AccordionComponent>

        <AccordionComponent
            answer="Het is aan te raden om ten minste 30 minuten van tevoren een taxi te boeken. Voor ritten naar het vliegveld raden wij aan om 24 uur van tevoren te reserveren."
            question="Hoeveel van tevoren moet ik een taxi boeken?">
        </AccordionComponent>

        <AccordionComponent
            answer="Nee, er zijn geen extra kosten voor normale bagage. Voor grote of extra bagage kan een kleine toeslag gelden."
            question="Zijn er extra kosten voor bagage?">
        </AccordionComponent>

        <AccordionComponent
            answer="Neem contact met ons op via +31 6 16439395 of info@taxi4go.nl en geef ons de details van uw rit. Wij zullen ons best doen om het verloren voorwerp terug te vinden."
            question="Wat moet ik doen als ik iets in de taxi ben vergeten?">
        </AccordionComponent>

        <AccordionComponent
            answer="Onze tarieven variëren afhankelijk van de afstand en het tijdstip van de dag. Voor een nauwkeurige prijsopgave kunt u onze tariefcalculator op de website gebruiken of contact opnemen met onze klantenservice."
            question="Wat zijn de tarieven voor ritten binnen de stad?">
        </AccordionComponent>

      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../../../assets/css/custom";

.custom-container{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

h2 {
  font-size: 2.5rem;
  font-weight: bold;
}

p{
  font-size: 1.3rem;
}

.side-contact{
  color: $primary;
}

.brand-name {
  color: $primary;
}

.accordion {
  margin-bottom: 10px; // Adjust the spacing between accordions
}

</style>