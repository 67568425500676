import { createRouter, createWebHistory } from 'vue-router';
import HomeComponent from "@/components/Pages/Home/HomeComponent.vue";
import FAQComponent from "@/components/Pages/FAQ/FAQComponent.vue";
import OverOnsComponent from "@/components/Pages/Over/OverOnsComponent.vue";
import ZakelijkVervoerComponent from "@/components/Pages/ZakelijkVervoer/ZakelijkVervoerComponent.vue";
import LuchthavenVervoer from "@/components/Pages/LuchthavenVervoer/LuchthavenVervoer.vue";
import LuchthavenBooking from "@/components/Pages/LuchthavenVervoer/LuchthavenBooking/LuchthavenBooking.vue";
import BookingConfirmationComponent from "@/components/Pages/Home/Booking/BookingConfirmationComponent.vue";
import PaymentFailComponent from "@/components/Payment/PaymentFailComponent.vue";

const routes = [
    {
        path: '/',
        component: HomeComponent,
        props: true,
        name: 'home',
        meta: {
            title: "Home"
        },
    },
    {
        path: '/veelgestelde-vragen',
        component: FAQComponent,
        props: true,
        name: 'veelgestelde-vragen',
        meta: {
            title: "Veelgestelde vragen"
        },
    },
    {
        path: '/over-ons',
        component: OverOnsComponent,
        props: true,
        name: 'over-ons',
        meta: {
            title: "Over ons"
        },
    },
    {
        path: '/zakelijk-vervoer',
        component: ZakelijkVervoerComponent,
        props: true,
        name: 'zakelijk-vervoer',
        meta: {
            title: "Zakelijk vervoer"
        },
    },
    {
        path: '/luchthaven-vervoer',
        component: LuchthavenVervoer,
        props: true,
        name: 'luchthaven-vervoer',
        meta: {
            title: "Luchthaven vervoer"
        },
    },
    {
        path: '/luchthaven-rit',
        component: LuchthavenBooking,
        props: true,
        name: 'luchthaven-rit',
        meta: {
            title: "Luchthaven rit"
        },
    },
    {
        path: '/bevestiging-rit',
        component: BookingConfirmationComponent,
        props: true,
        name: 'bevestiging-rit',
        meta: {
            title: "Bevestiging rit"
        },
    },
    {
        path: '/betalings-fout',
        component: PaymentFailComponent,
        props: true,
        name: 'betalings-fout',
        meta: {
            title: "Betalings fout"
        },
    },
];

export const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0, left: 0}
    }
});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title || 'Default Page Title';
    next();
});


