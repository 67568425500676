<script>
/* global Stripe */
export default {
  name: "LuchthavenControlBooking",
  props: {
    contactBooking: Object
  },
  data(){
    return {
      confirmation: false,
      stripe: null,
      finalBooking: {
        ophaaladres: this.contactBooking.ophaaladres,
        bestemming: this.contactBooking.bestemming,
        ophaaldatum: this.contactBooking.ophaaldatum,
        tijd: this.contactBooking.tijd,
        price: this.contactBooking.price,
        distance: this.contactBooking.distance,
        duration: this.contactBooking.duration,
        voornaam: this.contactBooking.voornaam,
        achternaam: this.contactBooking.achternaam,
        email: this.contactBooking.email,
        telefoonnummer: this.contactBooking.telefoonnummer,
        personen: this.contactBooking.personen,
        baggage: this.contactBooking.baggage,
        paymentMethod: ''
      }
    }
  },
  mounted() {
    this.stripe = Stripe('pk_test_51Pno2xRoxqyTqP9Eq46GOaUBPVrQcTHTL0qZ16EElozxcIdyTvkstskmlyOmyN9M20JERioelSubwCuQwlTJNTs100Pv3RV8do')
  },
  methods: {
    submitForm() {
      if (this.finalBooking.paymentMethod === 'Nu online Betalen'){
        this.finalBooking.paymentMethod = 'Er is online afgerekend'
        this.handlePayment()
      } else if (this.finalBooking.paymentMethod === 'Betaal met pin of creditcard in taxi') {
        this.finalBooking.paymentMethod = 'Er word met pin of creditcard afgerekend in de taxi'
        this.setBookingInStorage();
        this.$router.push({name: 'bevestiging-rit'})
      } else {
        this.finalBooking.paymentMethod = 'Er word contant afgerekend in de taxi'
        this.setBookingInStorage();
        this.$router.push({name: 'bevestiging-rit'})
      }
    },
    async handlePayment() {

      fetch('http://localhost:3000/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({amount: this.contactBooking.price * 100}), // Stripe expects amounts in cents
      })
          .then(response => response.json())
          .then(data => {
            this.setBookingInStorage();
            return this.stripe.redirectToCheckout({sessionId: data.sessionId});
          })
          .then((result) => {
            if (result.error) {
              // Display error to the customer
              alert(result.error.message);
            }
          })
          .catch((error) => {
            console.error('Error:', error);
          });
    },
    setBookingInStorage() {
      localStorage.setItem('bookingDetails', JSON.stringify(this.finalBooking));
    },
  }
}
</script>

<template>
  <div v-if="confirmation === false" class="container my-5" style="max-width: 80%;">
    <h2 class=" mb-4">Boek uw rit</h2>
    <form @submit.prevent="submitForm" v-motion-slide-right class="mt-5 custom-form">
      <h2>Rit gegevens</h2>
      <div class="row">
        <div class="column">
          <div class="">
            <h3>Ophaaladres</h3>
            <p>{{ contactBooking.ophaaladres }}</p>
          </div>
        </div>
        <div class="column">
          <div class="">
            <h3>Bestemming</h3>
            <p>{{ contactBooking.bestemming }}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="column">
          <div class="">
            <h3>Ophaaldatum</h3>
            <p>{{ contactBooking.ophaaldatum }}</p>
          </div>
        </div>
        <div class="column">
          <div class="">
            <h3>Tijd</h3>
            <p>{{ contactBooking.tijd }}</p>
          </div>
        </div>
      </div>

      <div class="row mb-3 text-center">
        <div class="col info-circle">
          <div class="circle text-white">
            <h3>Prijs</h3>
            <p>€ {{ contactBooking.price }}</p>
          </div>
        </div>
        <div class="col info-circle">
          <div class="circle text-white">
            <h3>Afstand</h3>
            <p>{{ contactBooking.distance }}</p>
          </div>
        </div>
        <div class="col info-circle">
          <div class="circle text-white">
            <h3>Duur</h3>
            <p>{{ contactBooking.duration }} min</p>
          </div>
        </div>
      </div>

      <h2>Contact gegevens</h2>
      <div class="row">
        <div class="column">
          <div class="field">
            <h3>Voornaam</h3>
            <p>{{ contactBooking.voornaam }}</p>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <h3>Achternaam</h3>
            <p>{{ contactBooking.achternaam }}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="column">
          <div class="field">
            <h3>Email</h3>
            <p>{{ contactBooking.email }}</p>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <h3>Telefoonnummer</h3>
            <p>{{ contactBooking.telefoonnummer }}</p>
          </div>
        </div>
      </div>

      <h2>Personen & Baggage</h2>
      <div class="row">
        <div class="column">
          <div class="field">
            <h3>Personen</h3>
            <p>{{ contactBooking.personen }}</p>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <h3>Baggage</h3>
            <p>{{ contactBooking.baggage }}</p>
          </div>
        </div>
      </div>

      <div>
        <h2>Betalingswijze</h2>

        <div class="form-check">
          <input class="form-check-input" type="radio" id="online-betalen" name="betalingswijze" value="Nu online Betalen" v-model="finalBooking.paymentMethod">
          <label class="form-check-label" for="online-betalen">
            Nu online Betalen
          </label>
        </div>

        <div class="form-check">
          <input class="form-check-input" type="radio" id="pin-taxi" name="betalingswijze" value="Betaal met pin of creditcard in taxi" v-model="finalBooking.paymentMethod">
          <label class="form-check-label" for="pin-taxi">
            Betaal met pin of creditcard in taxi
          </label>
        </div>

        <div class="form-check">
          <input class="form-check-input" type="radio" id="contant-taxi" name="betalingswijze" value="Betaal contant in taxi" v-model="finalBooking.paymentMethod">
          <label class="form-check-label" for="contant-taxi">
            Betaal contant in taxi
          </label>
        </div>
      </div>

      <button type="submit" class="btn custom-button">Rit bevestigen</button>
    </form>
  </div>

</template>

<style scoped lang="scss">
@import "../../../../assets/css/custom";

.back-button {
  color: $primary;
  float: left;
  cursor: pointer;
  width: 10%;
}

.back-button i {
  color: $primary;
}

.custom-button {
  background: $primary;
  color: $secondary;
  border: solid 2px $primary;
}

.custom-button:hover {
  background: $secondary;
  color: $primary;
  border: solid 2px $primary;
}

.custom-form {
  border: 2px solid $primary;
  padding: 2%;
  background: $secondary;


  h2 {
    position: relative;
    margin-bottom: 20px;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background: $primary;
      position: absolute;
      left: 0;
      bottom: -10px;
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .column {
      flex: 1;

      &:not(:last-child) {
        margin-right: 20px;
      }

      .field {
        display: flex;
        align-items: center;

        h3 {
          margin-bottom: 0;
          margin-right: 10px;
          font-weight: bold;
          font-size: 1.1rem;
        }

        p {
          margin: 0 0 0 auto;
          font-size: 1rem;
          text-align: right;
        }
      }
    }
  }

  h3 {
    margin-bottom: 0;
    margin-right: 10px;
    font-weight: bold;
    font-size: 1.1rem;
  }

  .info-circle {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .circle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: 10px auto;
    background: $primary;
  }

  .circle h3 {
    font-weight: bold;
    font-size: 1.5rem;
  }

  .circle p {
    font-size: 1.3rem;
  }

}

</style>