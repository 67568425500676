<script>
import { Fees } from "@/enums/fees";

export default {
  name: "ContactBookingFormComponent",
  props: {
    booking: Object,
  },
  data() {
    return {
      contactData: {
        voornaam: '',
        achternaam: '',
        email: '',
        telefoonnummer: '',
        personen: 1,
        baggage: 'Geen baggage'
      },
      price: ''
    }
  },
  mounted() {
    this.calculatePrice(this.booking.distance)
  },
  methods: {
    submitForm() {
      console.log('Form submitted with:', this.contactData);
      this.emitData()
    },
    calculatePrice(distance) {
      // make distance an int
      const splitDistance = distance.split('km')
      const intDistance = parseInt(splitDistance[0])

      let price = Fees.INSTAP_TARIEF + Fees.MINIMUM_TARIEF + (Fees.KILOMETER_TARIEF * intDistance)

      this.price = price.toFixed(2);
    },
    emitData() {
      this.$emit('contactData', {
        ophaaladres: this.booking.ophaaladres,
        bestemming: this.booking.bestemming,
        ophaaldatum: this.booking.ophaaldatum,
        tijd: this.booking.tijd,
        price: this.price,
        distance: this.booking.distance,
        duration: this.booking.duration,
        voornaam: this.contactData.voornaam,
        achternaam: this.contactData.achternaam,
        email: this.contactData.email,
        telefoonnummer: this.contactData.telefoonnummer,
        personen: this.contactData.personen,
        baggage: this.contactData.baggage
      })
    },
    emitNull(){
      this.$emit('return-null', null)
    }
  }

}
</script>

<template>

  <form @submit.prevent="submitForm" class="mt-5">
    <div class="row mb-3">
      <p class="back-button" @click="emitNull"><i class="fa-solid fa-backward me-2"></i>Terug</p>
    </div>

    <div class="row mb-3">
      <div class="col info-title">
        <h3>Ophaaladres</h3>
        <p>{{ booking.ophaaladres }}</p>
      </div>
      <div class="col info-title">
        <h3>Bestemming</h3>
        <p>{{ booking.bestemming }}</p>
      </div>
      <div class="col info-title">
        <h3>Ophaaldatum</h3>
        <p>{{ booking.ophaaldatum }}</p>
      </div>
      <div class="col info-title">
        <h3>Tijd</h3>
        <p>{{ booking.tijd }}</p>
      </div>
    </div>

    <div class="row mb-3 text-center">
      <div class="col info-circle">
        <div class="circle text-white">
          <h3>Prijs</h3>
          <p>€ {{ price }}</p>
        </div>
      </div>
      <div class="col info-circle">
        <div class="circle text-white">
          <h3>Afstand</h3>
          <p>{{ booking.distance }}</p>
        </div>
      </div>
      <div class="col info-circle">
        <div class="circle text-white">
          <h3>Duur</h3>
          <p>{{ booking.duration }} min</p>
        </div>
      </div>
    </div>
    
    <h2>Contactgegevens</h2>
    <div class="row mb-3">
      <div class="col-md-6">
        <label for="voornaam" class="form-label">Voornaam</label>
        <input required type="text" class="form-control" id="voornaam" v-model="contactData.voornaam">
      </div>
      <div class="col-md-6">
        <label for="achternaam" class="form-label">Achternaam</label>
        <input required type="text" class="form-control" id="achternaam" v-model="contactData.achternaam">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-6">
        <label for="email" class="form-label">Email</label>
        <input required type="email" class="form-control" id="email" v-model="contactData.email">
      </div>
      <div class="col-md-6">
        <label for="telefoonnummer" class="form-label">Telefoonnummer</label>
        <input required type="text" class="form-control" id="telefoonnummer" v-model="contactData.telefoonnummer">
      </div>
    </div>

    <h2>Personen & Baggage</h2>
    <div class="row mb-3">
      <div class="row mb-3">
        <div class="col-md-6">
          <label for="personen" class="form-label">Personen</label>
          <input
              required
              type="number"
              class="form-control"
              id="personen"
              v-model="contactData.personen"
              :min="1"
              :max="4"
              step="1"
          >
        </div>
        <div class="col-md-6">
          <label for="baggage" class="form-label">Baggage*</label>
          <select name="baggage" class="form-control" id="baggage" v-model="contactData.baggage">
            <option value="Geen baggage">Geen baggage</option>
            <option value="1 kleine koffer">1 kleine koffer</option>
            <option value="2 kleine koffers">2 kleine koffers</option>
            <option value="1 grote koffer">1 grote koffer</option>
            <option value="2 grote koffers">2 grote koffers</option>
            <option value="1 kleine koffer en 1 grote koffer">1 kleine koffer en 1 grote koffer</option>
          </select>
        </div>
      </div>
    </div>

    <p>*Als u twijfelt of uw baggage past in de auto, kunt u het altijd even navragen bij de chauffeur: +31 6 12345678</p>

    <button type="submit" class="btn custom-button">Gegevens controleren</button>
  </form>

</template>

<style scoped lang="scss">
@import "../../../../assets/css/custom";

.back-button {
  color: $primary;
  float: left;
  cursor: pointer;
  width: 10%;
}

.back-button i{
  color: $primary;
}

.info-title{
  text-align: center;
}

.info-title h3{
  font-weight: bold;
  font-size: 1.5rem;
}

.info-title p {
  font-size: 1rem;
}

.info-circle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: 10px auto;
  background: $primary;
}

.circle h3 {
  font-weight: bold;
  font-size: 1.5rem;
}

.circle p {
  font-size: 1.3rem;
}

.custom-button {
  background: $primary;
  color: $secondary;
  border: solid 2px $primary;
}

.custom-button:hover {
  background: $secondary;
  color: $primary;
  border: solid 2px $primary;
}

.input-group {
  position: relative;
}

label {
  font-size: 1rem;
}

form {
  border: 2px solid $primary;
  padding: 2%;
  background: $secondary;
}

</style>