<script>
import LuchthavenItem from "@/components/Pages/LuchthavenVervoer/LuchthavenItem.vue";
export default {
  name: "LuchthavenVervoer",
  components: {
    LuchthavenItem
  },
  methods: {
    handleItemClick(item) {
      localStorage.setItem('luchthavenDetails', JSON.stringify(item));
      this.$router.push({name: 'luchthaven-rit'})
    }
  }
}
</script>

<template>
  <div class="custom-container">
    <h1 class="mt-5 mb-5">Waar wilt u heen?</h1>

    <div class="row">
      <div class="col-md-4 mb-4">
        <LuchthavenItem
            luchthaven-naam="Schiphol Amsterdam Airport"
            luchthaven-adres="Aankomstpassage 1,"
            luchthaven-postcode="1118 AX Schiphol"
            @item-clicked="handleItemClick"
        />
      </div>
      <div class="col-md-4 mb-4">
        <LuchthavenItem
            luchthaven-naam="Eindhoven Airport"
            luchthaven-adres="Luchthavenweg 25,"
            luchthaven-postcode="5657 EA Eindhoven"
            @item-clicked="handleItemClick"
        />
      </div>
      <div class="col-md-4 mb-4">
        <LuchthavenItem
            luchthaven-naam="Groningen Airport Eelde"
            luchthaven-adres="Machlaan 14A,"
            luchthaven-postcode="9761 TK Eelde"
            @item-clicked="handleItemClick"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-md-4 mb-4">
        <LuchthavenItem
            luchthaven-naam="Rotterdam The Hague Airport"
            luchthaven-adres="Rotterdam Airportplein 60,"
            luchthaven-postcode="3045 AP Rotterdam"
            @item-clicked="handleItemClick"
        />
      </div>
      <div class="col-md-4 mb-4">
        <LuchthavenItem
            luchthaven-naam="Maastricht Airport"
            luchthaven-adres="Vliegveldweg 90,"
            luchthaven-postcode="6199 AD Maastricht Airport"
            @item-clicked="handleItemClick"
        />
      </div>
      <div class="col-md-4 mb-4">
        <LuchthavenItem
            luchthaven-naam="Lelystad Airport"
            luchthaven-adres="Arendweg 7,"
            luchthaven-postcode="8218 PE Lelystad"
            @item-clicked="handleItemClick"
        />
      </div>
    </div>

    <p class="text-center info-text">Taxi4Go rijdt ook ritten van en naar luchthavens in België en Duitsland.
      Voor een rit naar een luchthaven in het buitenland, neem dan contact op via +31 6 12345678</p>

  </div>
</template>

<style scoped lang="scss">
@import "src/assets/css/custom.scss";

.custom-container {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

h1{
  text-align: center;
  color: $primary;
  font-size: 2.5rem;
}

.info-text{
  font-size: 1.3rem;
}
</style>