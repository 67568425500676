<script>
/* global google */
import "flatpickr/dist/flatpickr.css";
import Flatpickr from "vue-flatpickr-component";
import LuchthavenContactBooking
  from "@/components/Pages/LuchthavenVervoer/LuchthavenBooking/LuchthavenContactBooking.vue";

export default {
  name: "LuchthavenBooking",
  components: {
    LuchthavenContactBooking,
    Flatpickr
  },
  data() {
    return {
      formData: {
        ophaaladres: '',
        bestemming: this.luchthavenDetails ? this.luchthavenDetails.luchthavenNaam : '',
        ophaaldatum: '',
        tijd: '',
        distance: '',
        duration: ''
      },
      booking: null,
      config: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
        minuteIncrement: 10,
      },
      todayDate: this.getTodayDate(),
      luchthavenDetails: null
    };
  },
  mounted() {

    this.initAutocomplete()


    this.luchthavenDetails = JSON.parse(localStorage.getItem('luchthavenDetails'))

    if (this.luchthavenDetails) {
      this.formData.bestemming = this.luchthavenDetails.adres + ' ' + this.luchthavenDetails.postcode + ', Netherlands';
    }

  },
  methods: {
    getTodayDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },

    submitForm() {
      this.calculateDistance()
    },

    initAutocomplete() {
      const options = {
        types: ['geocode']
      };

      const ophaaladresInput = document.getElementById('ophaaladres');

      ophaaladresInput.placeholder = ''

      const ophaaladresAutocomplete = new google.maps.places.Autocomplete(ophaaladresInput, options);

      ophaaladresAutocomplete.addListener('place_changed', () => {
        const place = ophaaladresAutocomplete.getPlace();
        this.formData.ophaaladres = place.formatted_address;
      });

    },
    calculateDistance() {
      if (typeof google !== 'undefined') {
        const service = new google.maps.DistanceMatrixService();
        service.getDistanceMatrix({
          origins: [this.formData.ophaaladres],
          destinations: [this.formData.bestemming],
          travelMode: google.maps.TravelMode.DRIVING,
        }, (response, status) => {
          if (status === google.maps.DistanceMatrixStatus.OK) {
            const element = response.rows[0].elements[0];
            const distance = element.distance;
            const duration = element.duration;

            this.formData.distance = distance.text;
            this.formData.duration = Math.ceil(duration.value / 60);

            this.booking = this.formData

          } else {
            console.error(`Error calculating distance: ${status}`);
          }
        });
      }
    },
  }
}
</script>

<template>
  <div v-if="booking == null" class="container my-5" style="max-width: 80%;">
    <h2 class=" mb-4">Boek uw rit</h2>
    <form @submit.prevent="submitForm" v-motion-slide-right>
      <div class="mb-3 position-relative">
        <label for="ophaaladres" class="form-label">Ophaaladres</label>
        <div class="input-group">
          <input required type="text" class="form-control pl-5" id="ophaaladres" v-model="formData.ophaaladres">
          <i class="fa-solid fa-location-dot position-absolute input-icon"></i>
        </div>
      </div>
      <div class="mb-3 position-relative">
        <label for="bestemming" class="form-label">Bestemming</label>
        <div class="input-group">
          <input required type="text" class="form-control pl-5" id="bestemming" disabled v-model="formData.bestemming">
          <i class="fa-solid fa-location-dot position-absolute input-icon"></i>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 mb-3">
          <label for="ophaaldatum" class="form-label">Ophaaldatum</label>
          <input
              required
              type="date"
              class="form-control"
              id="ophaaldatum"
              v-model="formData.ophaaldatum"
              :min="todayDate"
          >
        </div>
        <div class="col-md-6 mb-3">
          <label for="tijd" class="form-label">Tijd</label>
          <Flatpickr required v-model="formData.tijd" :config="config" class="form-control"></Flatpickr>
        </div>
      </div>
      <button type="submit" class="btn custom-button">Prijs berekenen</button>
    </form>
  </div>

  <div v-if="booking != null">
    <LuchthavenContactBooking :booking="booking"></LuchthavenContactBooking>
  </div>

</template>

<style scoped lang="scss">

@import "../../../../assets/css/custom";

.custom-button {
  background: $primary;
  color: $secondary;
  border: solid 2px $primary;
}

.custom-button:hover {
  background: $secondary;
  color: $primary;
  border: solid 2px $primary;
}

.input-group {
  position: relative;
}

.input-group input {
  padding-left: 30px;
}

.input-icon {
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: $primary;
}

label {
  font-size: 1rem;
}

form {
  border: 2px solid $primary;
  padding: 2%;
  background: $secondary;
}

.container {
  max-width: 80%;
}

</style>