<script>
export default {
  name: "TravelHighlightComponent",
  props: {
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  }
}
</script>

<template>
  <div class="highlight">
    <div class="icon">
      <i :class="icon"></i>
    </div>
    <div class="description">
      <div class="title-description">
        <h3>{{ title }}</h3>
      </div>
      <p>{{ description }}</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../../../assets/css/custom";

.icon{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem; /* Adjust size as needed */
  height: 3rem; /* Adjust size as needed */
  font-size: 1.5rem;
  border-radius: 50%;
  border: 2px solid $accent; /* Use your custom SCSS variable */
  position: relative; /* Add relative positioning */
  margin: 0 auto 1rem;
  background: #fff;
}

.description{
  border: 2px solid $accent;
  padding: 2%;
  width: 80%;
  margin: 0 auto;
  background: #fff;
  min-height: 14rem;
}

h3{
  text-align: center;
}
</style>