<script>
export default {
  name: "ContactData"
}
</script>

<template>
  <div class="contact-data">
    <div class="contact-content">
      <div class="contact-item">
        <i class="fa-solid fa-phone me-2"></i>
        <span>+31 6 16439395</span>
      </div>
      <div class="contact-item">
        <i class="fa-regular fa-envelope me-2"></i>
        <span>info@taxi4go.nl</span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../../../../assets/css/custom";
.contact-data {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primary; /* Background color of the circle */
  border-radius: 50%; /* Makes the container a circle */
  width: 300px; /* Adjust size as needed */
  height: 300px; /* Adjust size as needed */
  text-align: center; /* Center text horizontally */
  padding: 20px; /* Space inside the circle */
  color: $secondary; /* Text color */
}

.contact-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-item {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.contact-item i {
  font-size: 1.5rem; /* Icon size */
  color: $secondary; /* Icon color */
}

.contact-item span {
  display: block;
}

h2 {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  color: $secondary; /* Text color for the heading */
}

</style>
