<script>

import ContactData from "@/components/Pages/Home/Contact/ContactData.vue";

export default {
  name: "ZakelijkVervoerComponent",
  components: {ContactData},
}
</script>

<template>
  <div class="custom-container mt-5 mb-5">
    <h2 class="text-center">Zakelijk vervoer bij <span class="brand-name">Taxi4Go</span></h2>

    <div class="row mt-5">
      <div class="col-md-6 d-flex flex-column align-items-center text-center">
        <div class="box">
          <span class="icon-circle mb-5"><i class="fa-solid fa-car"></i></span>
          <h3 class="mb-3">Bedrijfsleven</h3>
          <p>
            Zakelijk vervoer speelt een cruciale rol in het bedrijfsleven, waarbij betrouwbaarheid en comfort essentieel zijn voor zowel medewerkers als klanten. Bij Taxi4Go bieden wij op maat gemaakte zakelijke vervoersoplossingen die naadloos aansluiten op uw bedrijfsbehoeften. Onze diensten zijn beschikbaar op factuurbasis, zodat u eenvoudig en efficiënt kunt afrekenen.

            Naast ons standaard zakelijk vervoer bieden wij ook uitgebreide opties zoals groepsvervoer en VIP-vervoer, perfect afgestemd op uw specifieke wensen. Ons team van ervaren chauffeurs en onze moderne voertuigen staan 24/7 voor u klaar om ervoor te zorgen dat al uw zakelijke ritten vlekkeloos verlopen. Of het nu gaat om dagelijkse pendeldiensten, klantbezoeken of speciale evenementen, wij garanderen een hoogwaardige service en een professionele benadering. Bij Taxi4Go staan wij altijd klaar om aan al uw zakelijke vervoersbehoeften te voldoen.
          </p>
        </div>
      </div>
      <div class="col-md-6 d-flex flex-column align-items-center text-center">
        <div class="box">
          <span class="icon-circle mb-5"><i class="fa-solid fa-road"></i></span>
          <h3 class="mb-3">Comfort</h3>
          <p>
            Het is van groot belang dat zakelijk vervoer van de hoogste kwaliteit is, aangezien een goede eerste indruk tijdens een zakelijke bijeenkomst vaak begint bij de aankomst van uw gasten. Bij Taxi4Go begrijpen we dit als geen ander. Onze professionele chauffeurs zijn speciaal getraind om een uitstekende service te bieden en uw gasten veilig en efficiënt te vervoeren.

            We weten dat tijd van essentieel belang is in het bedrijfsleven. Daarom garanderen wij dat onze chauffeurs altijd stipt op de afgesproken locatie arriveren, zodat u zich zonder zorgen kunt concentreren op uw werkzaamheden. Met onze comfortabele voertuigen en betrouwbare service kunt u erop vertrouwen dat uw zakelijke vervoersbehoeften optimaal worden vervuld. Wij zetten ons in om uw zakelijke ritten zo soepel en aangenaam mogelijk te maken, en streven ernaar om elke rit tot een onvergetelijke ervaring te maken.
          </p>
        </div>
      </div>
    </div>

    <div class="content-section mt-5 mb-5">
      <div class="row">
        <!-- Left Column -->
        <div class="col-md-6 d-flex justify-content-center align-items-center">
          <h2 class="text-center">Om in aanmerking te komen met zakelijk vervoer, neem dan graag contact met ons op</h2>
        </div>
        <!-- Right Column -->
        <div class="col-md-6 d-flex justify-content-center align-items-center">
          <ContactData></ContactData>
        </div>
      </div>
    </div>

  </div>

</template>

<style scoped lang="scss">
@import "../../../assets/css/custom";

.custom-container {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

h2 {
  font-size: 2.5rem;
  font-weight: bold;
}

.brand-name {
  color: $primary;
}

.custom-button {
  background: $primary;
  color: $secondary;
  border: solid 2px $primary;
}

.custom-button:hover {
  background: $secondary;
  color: $primary;
  border: solid 2px $primary;
}

.form-label {
  font-size: 1rem;
}

form {
  border: 2px solid $primary;
  padding: 2%;
  background: $secondary;
}

.box{
  background: $secondary;
  border: 2px solid $primary;
  width: 80%;
  padding: 2%;
}
.icon-circle i{
  font-size: 2rem;
}

.text-center h3 {
  font-size: 1.5rem;
  font-weight: bold;
}

.text-center p {
  font-size: 1rem;
}

</style>