import { createApp } from 'vue';
import App from './App.vue';
import {router} from "@/router/router";
// Import Custom Bootstrap CSS
import '@/assets/css/custom.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
// Import Vue Motion
import { MotionPlugin } from '@vueuse/motion';
// Import Font Awesome CSS
import '@fortawesome/fontawesome-free/css/all.css';

const app = createApp(App);

app.use(router);
app.use(MotionPlugin)
app.mount('#app');
