<script>
export default {
  name: "PaymentFailComponent",
  mounted() {
    localStorage.removeItem("bookingDetails")
  },
  methods: {
    retryPayment() {
      this.$router.push('/'); // Example: Redirect to payment page
    },
    contactSupport() {
      window.location.href = "mailto:info@taxi4go.nl"; // Example: Open mail client
    },
  }
}
</script>

<template>
  <div class="payment-fail-container">
    <div class="icon">
      <i class="fa-solid fa-times-circle"></i>
    </div>
    <h1>Sorry, uw betaling is niet gelukt.</h1>
    <p>Het lijkt erop dat er iets mis is gegaan. Probeer het later nog eens of neem contact op met onze klantenservice.</p>
    <div class="actions">
      <button class="btn btn-primary" @click="retryPayment">Opnieuw boeken</button>
      <button class="btn btn-secondary" @click="contactSupport">Contact Ondersteuning</button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.payment-fail-container {
  text-align: center;
  padding: 40px;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 8px;
  color: #721c24;
  max-width: 500px;
  margin: 50px auto;

  .icon {
    font-size: 50px;
    color: #721c24;
    margin-bottom: 20px;
  }

  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  p {
    font-size: 16px;
    margin-bottom: 30px;
  }

  .actions {
    display: flex;
    justify-content: center;
    gap: 15px;

    .btn {
      font-size: 16px;
      padding: 10px 20px;
      border-radius: 4px;
      cursor: pointer;
    }

    .btn-primary {
      background-color: #d9534f;
      color: #fff;
      border: none;
    }

    .btn-primary:hover {
      background-color: #c9302c;
    }

    .btn-secondary {
      background-color: #6c757d;
      color: #fff;
      border: none;
    }

    .btn-secondary:hover {
      background-color: #5a6268;
    }
  }
}
</style>