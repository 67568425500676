<script>
import TopBarComponent from "@/components/Navbar/TopBarComponent.vue";
export default {
  name: "NavbarComponent",
  components: {
    TopBarComponent
  }
}
</script>

<template>
  <TopBarComponent></TopBarComponent>
  <nav class="navbar navbar-expand-lg">
    <router-link to="/" class="navbar-brand ms-3">Taxi4Go</router-link>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav ms-auto me-5">
        <li class="nav-item">
          <router-link to="/" class="nav-link">Home</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/over-ons" class="nav-link">Over ons</router-link>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Diensten
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><router-link to="/luchthaven-vervoer" class="nav-link">Luchthaven vervoer</router-link></li>
            <li><router-link to="/zakelijk-vervoer" class="nav-link">Zakelijk vervoer</router-link></li>
          </ul>
        </li>
        <li class="nav-item">
          <router-link to="/veelgestelde-vragen" class="nav-link">FAQ</router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<style scoped lang="scss">
@import "src/assets/css/custom.scss";
.navbar{
  background: $secondary;
  border-bottom: 1px solid $accent;
}

.navbar-brand {
  font-size: 2rem;
  font-weight: bold;
  color: $accent
}

li a{
  font-size: 1.5rem !important;
  color: $accent !important;
}

/* Custom styles for dropdown on hover */
.navbar-nav .dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;
  min-width: 230px;
}

</style>