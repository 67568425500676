<template>
  <NavbarComponent></NavbarComponent>
  <router-view></router-view>
  <FooterComponent></FooterComponent>
</template>

<script>
import NavbarComponent from "@/components/Navbar/NavbarComponent.vue";
import FooterComponent from "@/components/Footer/FooterComponent.vue";

export default {
  name: 'App',
  components: {
    NavbarComponent,
    FooterComponent
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

</style>
