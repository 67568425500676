<script>
export default {
  name: "LuchthavenItem",
  props: {
    luchthavenNaam: String,
    luchthavenAdres: String,
    luchthavenPostcode: String
  },
  methods: {
    handleClick(){
      this.$emit('item-clicked', {
        naam: this.luchthavenNaam,
        adres: this.luchthavenAdres,
        postcode: this.luchthavenPostcode
      })
    }
  }
}
</script>

<template>
  <div @click="handleClick" class="card p-3 mb-4">
    <h2 class="card-title">{{ luchthavenNaam }}</h2>
    <div class="mt-2">
      <h5 class="card-subtitle mb-1">{{ luchthavenAdres }}</h5>
      <h5 class="card-subtitle">{{ luchthavenPostcode }}</h5>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/css/custom.scss";

.card {
  border: 2px solid $primary;
  border-radius: 0.25rem;
  background-color: $secondary;
  color: $accent;
  width: 100%;
  transition: background-color 0.5s, box-shadow 0.3s ease;
  cursor: pointer;
}

.card:hover{
  background: #fff;
  box-shadow: -5px -5px 15px rgba(0, 0, 0, 0.15), /* Top and left shadow */
                0px 0px 5px rgba(0, 0, 0, 0.1);    /* Slight shadow on the other sides */
}

.card-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: $primary;
}

.card-subtitle {
  font-size: 1.3rem;
}

</style>