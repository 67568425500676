<script>
import emailjs from "emailjs-com";
import {Email} from "@/enums/email";


export default {
  name: "BookingConfirmationComponent",
  data() {
    return {
      bookingDetails: ''
    }
  },
  mounted() {
    this.bookingDetails = JSON.parse(localStorage.getItem('bookingDetails'))

    if (this.bookingDetails) {
      this.sendConfirmationEmail()
    } else {
      this.$router.replace('/')
    }
    
  },
  methods: {
    sendConfirmationEmail() {

      emailjs.send(Email.SERVICE_ID, Email.DRIVER_TEMPLATE, this.bookingDetails, Email.USER_ID)
          .then((response) => {
            console.log('Email sent successfully:', response.status, response.text);
          }).catch((error) => {
        console.error('Error sending email:', error);
        alert('Failed to send email. Please try again.');
      })

      emailjs.send(Email.SERVICE_ID, Email.CUSTOMER_TEMPLATE, this.bookingDetails, Email.USER_ID)
          .then((response) => {
            console.log('Email sent successfully:', response.status, response.text);
          }).catch((error) => {
        console.error('Error sending email:', error);
        alert('Failed to send email. Please try again.');
      })

      localStorage.removeItem("bookingDetails")
    },
  }
}
</script>

<template>
  <form @submit.prevent v-motion-slide-right class="mt-5 mb-5">
    <h2>U rit is bevestigd!</h2>
    <p>U ontvangt een bevestigingsmail met de gegevens van uw rit</p>
  </form>
</template>

<style scoped lang="scss">
@import "../../../../assets/css/custom";

form {
  border: 2px solid $primary;
  padding: 2%;
  background: $secondary;
}

</style>