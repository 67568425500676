<template>
  <div class="accordion">
    <div class="accordion-header" @click="toggle">
      <h3>{{ question }}</h3>
      <span :class="{ 'expanded': isOpen }">&#9660;</span>
    </div>
    <transition
        name="accordion"
        @before-enter="beforeEnter"
        @enter="enter"
        @after-enter="afterEnter"
        @before-leave="beforeLeave"
        @leave="leave"
    >
      <div class="accordion-body" v-if="isOpen">
        <p>{{ answer }}</p>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "AccordionComponent",
  props: {
    question: {
      type: String,
      required: true,
    },
    answer: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
    beforeEnter(el) {
      el.style.maxHeight = '0';
      el.style.opacity = '0';
    },
    enter(el) {
      el.style.maxHeight = el.scrollHeight + 'px';
      el.style.opacity = '1';
    },
    afterEnter(el) {
      el.style.maxHeight = 'none';
    },
    beforeLeave(el) {
      el.style.maxHeight = el.scrollHeight + 'px';
      el.style.opacity = '1';
    },
    leave(el) {
      setTimeout(() => {
        el.style.maxHeight = '0';
        el.style.opacity = '0';
      }, 1);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../assets/css/custom";

.accordion {
  border: 1px solid $accent;
  margin: 10px 0;
  border-radius: 4px;
}

.accordion-header {
  background-color: $secondary;
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-header h3 {
  margin: 0;
}

.accordion-header span {
  transition: transform 0.5s;
}

.accordion-header span.expanded {
  transform: rotate(180deg);
}

.accordion-body {
  padding: 10px 15px;
  border-top: 1px solid $accent;
  background: #fff;
  overflow: hidden;
}

.accordion-enter-active,
.accordion-leave-active {
  transition: max-height 0.5s ease, opacity 0.5s ease;
}
</style>
