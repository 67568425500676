<script>
/* global google */
import "flatpickr/dist/flatpickr.css";
import Flatpickr from "vue-flatpickr-component";
import {GoogleApi} from "@/enums/google";
import PopupModal from "@/components/Pages/Home/Booking/PopupModal.vue";

export default {
  name: "BookingFormComponent",
  components: {
    Flatpickr,
    PopupModal
  },
  data() {
    return {
      formData: {
        ophaaladres: '',
        bestemming: '',
        ophaaldatum: '',
        tijd: ''
      },
      distance: '',
      duration: '',
      config: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
        minuteIncrement: 10,
      },
      todayDate: this.getTodayDate(),
      isModalVisible: false,
    };
  },
  mounted() {
    this.loadGoogleMapsScript().then(() => {
      this.initAutocomplete()
    })
  },
  methods: {
    getTodayDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },

    submitForm() {
      if (this.validateAddresses()){
        this.calculateDistance()
      } else{
        this.isModalVisible = true
      }
    },

    loadGoogleMapsScript() {
      return new Promise((resolve, reject) => {
        if (typeof google !== 'undefined') {
          resolve();
          return;
        }

        const script = document.createElement('script');
        script.src = GoogleApi.API_URL;
        script.async = true;
        script.defer = true;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    },

    initAutocomplete() {
      const options = {
        types: ['geocode']
      };

      const ophaaladresInput = document.getElementById('ophaaladres');
      const bestemmingInput = document.getElementById('bestemming');

      if (ophaaladresInput && bestemmingInput) {
        ophaaladresInput.placeholder = ''
        bestemmingInput.placeholder = ''
      }

      const ophaaladresAutocomplete = new google.maps.places.Autocomplete(ophaaladresInput, options);
      const bestemmingAutocomplete = new google.maps.places.Autocomplete(bestemmingInput, options);

      ophaaladresAutocomplete.addListener('place_changed', () => {
        const place = ophaaladresAutocomplete.getPlace();
        this.formData.ophaaladres = place.formatted_address;
      });

      bestemmingAutocomplete.addListener('place_changed', () => {
        const place = bestemmingAutocomplete.getPlace();
        this.formData.bestemming = place.formatted_address;
      });
    },
    checkIfAddressIsInAmsterdamCentre(address) {
      const postalCodePattern = /\b101[1-8]\b/g; // Matches 1011 to 1018
      const match = address.match(postalCodePattern);
      return match ? match[0] : null;
    },
    validateAddresses() {
      const ophaalPostalCode = this.checkIfAddressIsInAmsterdamCentre(this.formData.ophaaladres);
      const bestemmingPostalCode = this.checkIfAddressIsInAmsterdamCentre(this.formData.bestemming);

      if (ophaalPostalCode &&
          bestemmingPostalCode &&
          parseInt(ophaalPostalCode) >= 1011 &&
          parseInt(ophaalPostalCode) <= 1018 &&
          parseInt(bestemmingPostalCode) >= 1011 &&
          parseInt(bestemmingPostalCode) <= 1018)
      {
        return false;
      }

      return true
    },
    calculateDistance() {
      if (typeof google !== 'undefined') {
        const service = new google.maps.DistanceMatrixService();
        service.getDistanceMatrix({
          origins: [this.formData.ophaaladres],
          destinations: [this.formData.bestemming],
          travelMode: google.maps.TravelMode.DRIVING,
        }, (response, status) => {
          if (status === google.maps.DistanceMatrixStatus.OK) {
            const element = response.rows[0].elements[0];
            const distance = element.distance;
            const duration = element.duration;

            this.distance = distance.text;
            this.duration = Math.ceil(duration.value / 60);

            this.emitData()
          } else {
            console.error(`Error calculating distance: ${status}`);
          }
        });
      }
    },
    closeModal() {
      this.isModalVisible = false;
    },
    emitData() {
      this.$emit('formData', {
        ophaaladres: this.formData.ophaaladres,
        bestemming: this.formData.bestemming,
        ophaaldatum: this.formData.ophaaldatum,
        tijd: this.formData.tijd,
        distance: this.distance,
        duration: this.duration
      })
    }
  }
}
</script>

<template>

  <form @submit.prevent="submitForm" v-motion-slide-right class="mt-5">
    <div class="mb-3 position-relative">
      <label for="ophaaladres" class="form-label">Ophaaladres</label>
      <div class="input-group">
        <input required type="text" class="form-control pl-5" id="ophaaladres" v-model="formData.ophaaladres">
        <i class="fa-solid fa-location-dot position-absolute input-icon"></i>
      </div>
    </div>
    <div class="mb-3 position-relative">
      <label for="bestemming" class="form-label">Bestemming</label>
      <div class="input-group">
        <input required type="text" class="form-control pl-5" id="bestemming" v-model="formData.bestemming">
        <i class="fa-solid fa-location-dot position-absolute input-icon"></i>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-3">
        <label for="ophaaldatum" class="form-label">Ophaaldatum</label>
        <input
            required
            type="date"
            class="form-control"
            id="ophaaldatum"
            v-model="formData.ophaaldatum"
            :min="todayDate"
        >
      </div>
      <div class="col-md-6 mb-3">
        <label for="tijd" class="form-label">Tijd</label>
        <Flatpickr required v-model="formData.tijd" :config="config" id="tijd" class="form-control"></Flatpickr>
      </div>
    </div>
    <button type="submit" class="btn custom-button">Prijs berekenen</button>

    <PopupModal :show="isModalVisible" @close="closeModal">
      <h2>Rit is te kort</h2>
      <p>Taxi4Go rijdt geen korte ritten in het centrum van amsterdam</p>
    </PopupModal>

  </form>
</template>

<style lang="scss" scoped>
@import "../../../../assets/css/custom";

.custom-button {
  background: $primary;
  color: $secondary;
  border: solid 2px $primary;
}

.custom-button:hover {
  background: $secondary;
  color: $primary;
  border: solid 2px $primary;
}

.input-group {
  position: relative;
}

.input-group input {
  padding-left: 30px;
}

.input-icon {
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: $primary;
}

label {
  font-size: 1rem;
}

form {
  border: 2px solid $primary;
  padding: 2%;
  background: $secondary;
}

</style>