<script>
export default {
  name: "FooterComponent"
}
</script>

<template>
  <footer class="footer py-4">
    <div class="container">
      <div class="row text-center">
        <!-- Company Name -->
        <div class="col-md-4 mb-4 mb-md-0">
          <h6 class="footer-title">Betalingsmogelijkheden</h6>
          <p>U kunt direct betalen via onze website met iDeal. Daarbij kunt u bij ons in de taxi pinnen, met creditcard betalen of met contant geld betalen.</p>
          <img src="../../assets/img/betalingsmogelijkheden.png" class="img-fluid" width="290" height="30" alt="X">
        </div>
        <!-- Menu -->
        <div class="col-md-4 mb-4 mb-md-0">
          <h6 class="footer-title">Menu</h6>
          <ul class="list-unstyled">
            <li><router-link to="/">Home</router-link></li>
            <li><router-link to="/over-ons">Over ons</router-link></li>
            <li><router-link to="/luchthaven-vervoer">Luchthaven vervoer</router-link></li>
            <li><router-link to="/zakelijk-vervoer">Zakelijk vervoer</router-link></li>
            <li><router-link to="/veelgestelde-vragen">Veelgestelde vragen</router-link></li>
          </ul>
        </div>
        <!-- Contact -->
        <div class="col-md-4 mb-4 mb-md-0">
          <h6 class="footer-title">Contact</h6>
          <ul class="list-unstyled">
            <li><i class="fa-solid fa-phone me-2"></i><span>+31 6 16439395</span></li>
            <li><i class="fa-regular fa-envelope me-2"></i><span>info@taxi4go.nl</span></li>
          </ul>
        </div>
      </div>
      <hr>
      <p class="copyright">All rights served &copy; Taxi4Go 2024</p>
    </div>
  </footer>
</template>

<style scoped lang="scss">
@import "src/assets/css/custom.scss";
/* Adjust styling as per your design */
.footer {
  background-color: $accent; /* Dark background */
  color: $secondary;
}

.footer ul {
  padding-left: 0;
}

.footer li {
  list-style: none;
}

.footer a {
  text-decoration: none;
  color: inherit;
}

.footer a:hover {
  text-decoration: underline;
}

.footer i {
  font-size: 1.2rem;
  color: #ccc; /* Icon color */
}

.footer-title {
  font-size: 1.5rem;
}

.brand {
  font-size: 2rem;
}

.copyright {
  text-align: center;
}
</style>
